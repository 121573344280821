<template>
  <router-view />
</template>

<script>
export default {
  setup() {
    const tabItems = [
      { label: "Custom Processings", routeName: "ricustomprocessing" },
      { label: "Orientation", to: { name: "orientationsCollection" } },
      { label: "Cropping", to: { name: "croppingCollection" } },
      { label: "Annotations", to: { name: "annotationsCollection" } },
      { label: "Context Vision Input", to: { name: "CVInputCollection" } },
      { label: "Windowings", to: { name: "windowingsCollection" } },
    ]

    return {
      tabItems,
    }
  },
}
</script>
